import { TransformType } from '@mr-yum/cdn-image'
import { humanize } from '@mr-yum/frontend-core/dist/support/strings'
import { InfoOutlineIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import {
  AccentTag,
  getIsOnSpecialTagVisible,
  getIsPopularTagVisible,
  ImageUnavailableOverlay,
  ItemDietaryFilters,
  ItemModalBannerBadge,
  ItemPrice,
} from 'components/MenuItem/shared'
import { getDescription, getName } from 'components/MenuItem/utils'
import { useRewardItemDetails } from 'components/Rewards/hooks/useRewardItemDetails'
import { RewardsPriceTag } from 'components/Rewards/RewardsPriceTag'
import { Image } from 'components/Shared/Image'
import { Markdown } from 'components/Shared/Markdown'
import { useOrderingTypeContext } from 'contexts/VenueOrderContext'
import { OrderingType } from 'lib/gql'
import React, { ReactNode, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { MenuStoreContext } from 'stores/MenuStore'

import { ItemOptions } from '.'
import { AddUpsellForm } from './AddUpsellForm'
import { ItemForm, useItemFormControllerContext } from './ItemForm'
import { ItemOptionsSkeleton } from './ItemOptionsSkeleton'
import {
  CloseButton,
  ItemDetailsContent,
  ItemDetailsContentInner,
  ItemDetailsImage,
  ItemDetailsName,
  ItemDetailsWrapper,
} from './shared'
import { FormValues } from './types'

const MenuItemForm = ({ children }: { children: ReactNode }) => {
  const { handleSubmit } = useFormContext<FormValues>()
  const { onSubmit } = useItemFormControllerContext()
  return (
    <form
      className="flex-1"
      data-testid="item-options-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {children}
    </form>
  )
}

const Loader = () => (
  <div className="flex h-full">
    <div className="relative flex flex-1 flex-col justify-start">
      <ItemOptionsSkeleton />
    </div>
  </div>
)

/*
  The intent was to swap all the menu item details out for the upsell -> menu item.
  This works fine except for the image which has to be downloaded. On slower
  connections you might see the menu item image before the upsell -> menu item loads.
  Quick solution is to force a re-render by looking at the query param state.

  ## TODO cleaner fix:
  - pre-load the upsell menu item image when it is rendered or hovered over.
*/
const ImageCacheHack = () => {
  const { selectedUpsell, menuItem, isUpsell } = useItemFormControllerContext()

  return (
    <>
      {isUpsell && selectedUpsell?.menuItem.image && (
        <ItemDetailsImage>
          <Image
            image={selectedUpsell.menuItem.image}
            transform={TransformType.SQUARE}
            alt={selectedUpsell.menuItem.name ?? ''}
            sizes="500px"
            layout="fill"
            objectFit="cover"
            priority
          />

          {!selectedUpsell.menuItem.isAvailable && <ImageUnavailableOverlay />}
        </ItemDetailsImage>
      )}

      {!isUpsell && menuItem.image && (
        <ItemDetailsImage>
          <Image
            image={menuItem.image}
            transform={TransformType.SQUARE}
            alt={menuItem.name ?? ''}
            sizes="500px"
            layout="fill"
            objectFit="cover"
            priority
          />

          {!menuItem.isAvailable && <ImageUnavailableOverlay />}
        </ItemDetailsImage>
      )}
    </>
  )
}

export const ItemDetailsBody = ({
  orderingAvailable,
}: {
  itemSlug: string
  orderingAvailable: boolean
}) => {
  const { orderingType } = useOrderingTypeContext()

  const {
    selectedUpsell,
    menuItem,
    selectedUpsellGroup,
    handleClose,
    isUpsell,
    fetching,
  } = useItemFormControllerContext()
  const selectedMenuItem = useMemo(
    () => selectedUpsell?.menuItem ?? menuItem,
    [selectedUpsell, menuItem],
  )
  const { language } = useContext(MenuStoreContext)
  const description =
    (language === 'en'
      ? selectedMenuItem.description
      : getDescription(selectedMenuItem, language)) || ''
  const name = getName(selectedMenuItem, language) || ''
  const { priceData, rewardPrice } = selectedMenuItem
  const rewardDetails = useRewardItemDetails(rewardPrice)

  const isOnSpecialTagVisible = getIsOnSpecialTagVisible(priceData)
  const isPopularTagVisible = getIsPopularTagVisible({ menuItem, priceData })

  const isShowingTags =
    rewardDetails.isRewardsPriceTagVisible ||
    isOnSpecialTagVisible ||
    isPopularTagVisible

  const allergens = selectedMenuItem.allergens || []

  return (
    <ItemDetailsWrapper hasImage={!!selectedMenuItem.image}>
      <CloseButton onClick={handleClose} />

      <ImageCacheHack />

      <ItemDetailsContent>
        <div className="md:mb-2 md:overscroll-contain">
          <ItemDetailsContentInner>
            <div
              className={cn(
                'mb-4 flex-1 px-4 sm:mb-5 sm:px-5 md:mb-6 md:px-0',
                { 'mt-14': !selectedMenuItem.image },
              )}
            >
              {isShowingTags && (
                <div className="mb-2 flex space-x-2">
                  {rewardDetails.isRewardsPriceTagVisible && (
                    <RewardsPriceTag
                      isAffordable={rewardDetails.isRewardAffordable}
                      price={rewardDetails.rewardPriceInPoints}
                      noun={rewardDetails.noun}
                    />
                  )}
                  {isOnSpecialTagVisible ? (
                    <AccentTag>On special</AccentTag>
                  ) : (
                    isPopularTagVisible && <AccentTag>Popular</AccentTag>
                  )}
                </div>
              )}

              <ItemDetailsName>{name}</ItemDetailsName>

              <div className="my-body-md" data-testid="menu-item-description">
                <Markdown>{description}</Markdown>
              </div>
            </div>

            {allergens.length > 0 && (
              <div
                className={`flex items-start border-b px-4 pb-4 pt-4
            sm:px-5 sm:pb-5 sm:pt-5 md:border-none
            md:px-0 md:pb-6
            md:pt-0`}
              >
                <div className="mr-3">
                  <InfoOutlineIcon />
                </div>
                <div>
                  <div className="text-foreground-subtle my-body-md">
                    Contains:{' '}
                    {allergens.map((allergen, index) => (
                      <span key={index} style={{ fontWeight: 'bold' }}>
                        {index > 0
                          ? allergens.length > 1 &&
                            index + 1 === allergens.length
                            ? ', and '
                            : ', '
                          : ''}
                        {allergen === 'SOYA_OR_SOYBEANS'
                          ? 'soya/soybeans'
                          : humanize(allergen).toLowerCase()}
                      </span>
                    ))}
                    .
                  </div>
                </div>
              </div>
            )}

            <div className="flex items-center justify-between px-4 pt-4 sm:px-5 sm:pt-5 md:px-0 md:pt-0">
              <ItemPrice className="!mr-4 my-label-md" priceData={priceData} />

              {menuItem.dietaryTags && menuItem.dietaryTags.length > 0 && (
                <ItemDietaryFilters className="my-body-md">
                  {selectedMenuItem.dietaryTags?.join(',\u00A0')}
                </ItemDietaryFilters>
              )}
            </div>
          </ItemDetailsContentInner>
        </div>

        {!selectedMenuItem.isAvailableForOrdering &&
          orderingType !== OrderingType.Menu && (
            <ItemModalBannerBadge label="Not available to order" />
          )}

        {selectedMenuItem.isAvailableForOrdering && (
          <>
            {fetching ? (
              <Loader />
            ) : (
              <ItemForm>
                {isUpsell && selectedUpsell && selectedUpsellGroup && (
                  <AddUpsellForm
                    selectedUpsell={selectedUpsell}
                    selectedUpsellGroup={selectedUpsellGroup}
                  >
                    <ItemOptions
                      orderingType={orderingType}
                      orderingAvailable={orderingAvailable}
                    />
                  </AddUpsellForm>
                )}
                {!isUpsell && (
                  <MenuItemForm>
                    <ItemOptions
                      orderingType={orderingType}
                      orderingAvailable={orderingAvailable}
                    />
                  </MenuItemForm>
                )}
              </ItemForm>
            )}
          </>
        )}
      </ItemDetailsContent>
    </ItemDetailsWrapper>
  )
}
